<template>
    <Table
        :isLoading="isLoading"
        :isRecursive="true"
        :columnConfig="tableColumnConfig"
        :rows="tableShowData"
        :sortDefault="{ sort: 'desc', sortBy: 'receiveLifeFyb' }"
        :excelConfig="excelConfig"
        :toolbox="['columnSetting', 'excel', 'zooming']"
        :isUseCard="false"
        :isFocusRow="true"
        :descriptions="descriptions"
        :isFixedHeader="true"
    >
        <template v-slot:salesName="{ isHeader, row }">
            <div v-if="!isHeader" v-html="row.salesName" />
        </template>
        <template v-slot:license="{ isHeader, row }">
            <div
                v-if="!isHeader && typeof row.license === 'object'"
                class="license-icon"
            >
                <div
                    class="life-license-icon"
                    :class="row.license?.life ? '' : 'disabled'"
                />
                <div
                    class="property-license-icon"
                    :class="row.license?.property ? '' : 'disabled'"
                />
                <div
                    class="investment-license-icon"
                    :class="row.license?.investment ? '' : 'disabled'"
                />
                <div
                    class="currency-license-icon"
                    :class="row.license?.foreignCurrency ? '' : 'disabled'"
                />
            </div>
        </template>
    </Table>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import {
    intervalColumns,
    intervalDescriptions,
    intervalExcelDescriptions,
    intervalPerformanceHelper
} from '@/assets/javascripts/report/setting'
import { mapState } from 'vuex'

export default {
    name: 'IntervalTable',
    components: {
        Table
    },
    props: {
        bgName: {
            type: String,
            default: ''
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        stylerList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        licenseList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        performanceList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        infos: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        transformDetail: function (rows) {
            return rows.reduce((memo, row) => {
                const uid = row.uid
                const manCode = row.manCode
                const style = this.stylerList[uid]
                const p = this.performanceList[manCode]
                const license = this.licenseList[manCode]

                if (row.sub) {
                    if (style?.visible === false) {
                        memo = [...memo, ...this.transformDetail(row.sub)]
                    } else {
                        row._detail = this.transformDetail(row.sub)
                        this.getSumRow(row, row._detail)
                        memo.push(_.omit(row, 'sub'))
                    }
                } else {
                    /* end node */

                    if (style?.visible === false) {
                        return memo
                    }
                    // salesName用於轉過HTML的欄位
                    row.salesName = row.name || '-'
                    row.license = license
                    row.apStuName = row?.apStuName || '-'
                    row.titleTrans = row.titleTrans || '-'
                    row.registerDate = row.date?.substring(0, 10) || '-'
                    row.recentRegister = row.isRecentlyReg ? '是' : '否'

                    // belows are for Excel, will replace license column
                    row.licenseTrans = license?.life ? '壽' : ''
                    row.licenseTrans += license?.property ? '產' : ''
                    row.licenseTrans += license?.investment ? '投' : ''
                    row.licenseTrans += license?.foreignCurrency ? '外' : ''

                    row = intervalPerformanceHelper(row, p, this.$roundX)
                    memo.push(row)
                }
                return memo
            }, [])
        },
        getSumRow: function (row, detail) {
            Object.keys(this.tableColumnConfig).forEach((key) => {
                if (this.tableColumnConfig[key].position === 'right') {
                    row[key] = _.sumBy(detail, (o) => {
                        if (typeof o[key] !== 'number') return 0
                        else return o[key]
                    })
                }
            })
            // salesName用於轉過HTML的欄位
            row.salesName = `${row.name}&nbsp;&nbsp;&nbsp;${row.groupTitle}`
            row.apStuName = row?.apStuName || '-'
            row.titleTrans = row.titleTrans || '-'
            row.registerDate = row.registerDate || '-'
            row.license = row.license || '-'
            // belows are for Excel, will replace license column
            row.licenseTrans = '-'

            // sum percentage
            let isFinite = row.receiveLifeFyb > 0
            row.fourWinDivideLifeFyb = this.$toPercentage(
                isFinite ? row.fourWinFyb / row.receiveLifeFyb : 0,
                2
            )
            row.specialDivideLifeFyb = this.$toPercentage(
                isFinite ? row.specialFyb / row.receiveLifeFyb : 0,
                2
            )

            return row
        }
    },
    computed: {
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformDetail(dataset)
            return dataset
        },
        hasData: function () {
            return this.tableShowData.length > 0 || this.isLoading
        },
        excelFileName: function () {
            const name =
                this.filterLog.bgCode?.label || this.filterLog.manCode?.label
            return `單位業績查詢_${name}`
        },
        ...mapState(['tableDeep']),
        excelConfig: function () {
            return {
                fileName: this.excelFileName,
                filters: this.filterLog,
                descriptions: this.excelDescriptions,
                infos: this.infos
            }
        },
        tableColumnConfig: function () {
            let columns = _.cloneDeep(intervalColumns)
            //欄位相關的修訂來源統一化
            if (this.tableDeep > 7) {
                columns.salesName['widthPx'] = 220 + (this.tableDeep - 7) * 10
            } else {
                columns.salesName['widthPx'] = 220
            }
            if (
                this.filterLog.dataType?.value === 2 ||
                this.filterLog.dataType?.value === 3
            ) {
                columns.receiveLifeFyb.label = '區間核實 壽險FYC'
                columns.receiveLifeFyn.label = '區間核實 壽險件數'
                columns.receivePropertyFyb.label = '區間核實 產險FYC'
                columns.receivePropertyFyn.label = '區間核實 產險件數'
            }
            return columns
        }
    },
    watch: {
        listData: {
            handler: function () {
                this.$reloadTips()
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            //  tableColumnConfig: intervalColumns,
            descriptions: intervalDescriptions,
            excelDescriptions: intervalExcelDescriptions
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.table) {
    @for $i from 0 through 15 {
        .deep-#{$i} .sales-name {
            padding-left: 15px + 15px * $i;
        }
    }

    // 表頭tooltips的客製化設定
    .recent-register {
        position: relative;
        text-align: left;

        .recentRegister-tooltip {
            position: absolute;
            bottom: 8%;
            left: 45%;
        }
    }
}

:deep(.license-icon) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div {
        display: inline-block;
        vertical-align: -2px;
        width: 15px;
        height: 15px;
        margin-right: 2px;
        &:last-of-type {
            margin-right: 0;
        }
    }
    &.description {
        display: block;
        div {
            margin-right: 5px;
        }
    }
}
</style>
